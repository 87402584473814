define("gsn-reception-console/services/api", ["exports", "@globalspeechnetworks/ember-purecloud/services/api"], function (_exports, _api) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _api.default;
    }
  });
});