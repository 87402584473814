define("gsn-reception-console/templates/auth-done", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "pmfUhOXu",
    "block": "[[[1,[28,[35,0],[\"AuthDone\"],null]],[1,\"\\n\"],[46,[28,[37,2],null,null],null,null,null],[1,\"\\n\\n\"],[11,\"button\"],[24,0,\"btn btn-primary\"],[24,4,\"button\"],[4,[38,3],[[30,0],\"search\"],null],[12],[1,\"Search\"],[13]],[],false,[\"page-title\",\"component\",\"-outlet\",\"action\"]]",
    "moduleName": "gsn-reception-console/templates/auth-done.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});