define("gsn-reception-console/routes/console", ["exports", "@ember/routing/route"], function (_exports, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class ConsoleRoute extends _route.default {}

  _exports.default = ConsoleRoute;
});