define("gsn-reception-console/templates/login-error", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "RJNbSBU2",
    "block": "[[[1,[28,[35,0],[\"LoginError\"],null]],[1,\"\\n\"],[46,[28,[37,2],null,null],null,null,null],[1,\"\\nLogin Error\"]],[],false,[\"page-title\",\"component\",\"-outlet\"]]",
    "moduleName": "gsn-reception-console/templates/login-error.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});