define("gsn-reception-console/styles/tailwind.config", [], function () {
  "use strict";

  module.exports = {
    //mode: 'jit',
    purge: [],
    darkMode: false,
    // or 'media' or 'class'
    theme: {
      extend: {}
    },
    variants: {
      extend: {}
    },
    corePlugins: {
      ringColor: false
    },
    plugins: [require("postcss-import"), require("tailwindcss"), require("@tailwindcss/forms"), require("autoprefixer")]
  };
});