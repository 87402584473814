define("gsn-reception-console/templates/console-01", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "TCJIgsI7",
    "block": "[[[1,[28,[35,0],[\"Console01\"],null]],[1,\"\\n\"],[46,[28,[37,2],null,null],null,null,null],[1,\"\\n\"],[3,\" console-01 \"],[1,\"\\n\"],[10,0],[14,0,\"container mx-auto px-4 space-y-6 mt-16 bg-white\"],[12],[1,\"\\n\\n  \"],[10,0],[12],[1,\"\\n    \"],[10,\"label\"],[14,\"for\",\"search\"],[14,0,\"block text-sm font-medium text-gray-700\"],[12],[1,\"Quick search\"],[13],[1,\"\\n\\n    \"],[10,0],[14,0,\"mt-1 relative flex items-center\"],[12],[1,\"\\n      \"],[8,[39,3],[[24,3,\"search\"],[24,1,\"search\"],[24,0,\"shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full pr-12 sm:text-sm border-gray-300 rounded-md\"]],[[\"@type\",\"@value\"],[\"text\",[30,0,[\"searchString\"]]]],null],[1,\"\\n      \"],[3,\"<input type=\\\"text\\\" name=\\\"search\\\" id=\\\"search\\\" class=\\\"shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full pr-12 sm:text-sm border-gray-300 rounded-md\\\">\"],[1,\"\\n    \"],[13],[1,\"\\n\\n  \"],[13],[1,\"\\n\\n  \"],[10,\"ul\"],[14,\"role\",\"list\"],[14,0,\"grid grid-cols-1 gap-6 sm:grid-cols-3 lg:grid-cols-4 bg-white\"],[12],[1,\"\\n\"],[42,[28,[37,5],[[28,[37,5],[[30,1]],null]],null],null,[[[1,\"      \"],[8,[39,6],null,[[\"@user\",\"@status\",\"@name\",\"@searchString\"],[[30,2],[30,2,[\"status\"]],[30,2,[\"name\"]],[30,0,[\"searchString\"]]]],null],[1,\"\\n\"]],[2]],null],[1,\"  \"],[13],[1,\"\\n\\n\"],[13]],[\"@model\",\"user\"],false,[\"page-title\",\"component\",\"-outlet\",\"input\",\"each\",\"-track-array\",\"mini-person\"]]",
    "moduleName": "gsn-reception-console/templates/console-01.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});