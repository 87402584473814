define("gsn-reception-console/routes/console-01", ["exports", "@ember/routing/route", "@ember/service", "@ember/object"], function (_exports, _route, _service, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let Console01Route = (_dec = (0, _service.inject)('purecloud/api'), _dec2 = (0, _service.inject)('purecloud/notifications'), _dec3 = (0, _service.inject)('purecloud/webservice'), (_class = class Console01Route extends _route.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "purecloud", _descriptor, this);

      _initializerDefineProperty(this, "purecloudNotifications", _descriptor2, this);

      _initializerDefineProperty(this, "purecloudWs", _descriptor3, this);
    }

    async model() {
      let purecloudNotifications = this.purecloudNotifications;
      let purecloudWs = this.purecloudWs;
      return this.store.findAll('user').then(response => {
        return purecloudNotifications.channelId().then(channelId => {
          purecloudWs.start(channelId);
          let presTopics = response.mapBy('id').map(id => {
            return {
              id: `v2.users.${id}.presence`
            };
          });
          let convTopics = response.mapBy('id').map(id => {
            return {
              id: `v2.users.${id}.conversations.calls`
            };
          });
          let topics = presTopics.concat(convTopics);
          purecloudNotifications.postNotificationsChannelSubscriptions(channelId, topics);
          return response;
        });
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "purecloud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "purecloudNotifications", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "purecloudWs", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = Console01Route;
});