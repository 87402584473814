define("gsn-reception-console/router", ["exports", "@ember/routing/router", "gsn-reception-console/config/environment"], function (_exports, _router, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class Router extends _router.default {
    constructor() {
      super(...arguments);

      _defineProperty(this, "location", _environment.default.locationType);

      _defineProperty(this, "rootURL", _environment.default.rootURL);
    }

  }

  _exports.default = Router;
  Router.map(function () {
    this.route('post-auth', {
      path: '/:org_name/post-auth'
    });
    this.route('auth-done');
    this.route('login-error');
    this.route('console', {
      path: '/:org_name/console'
    });
    this.route('console-01', {
      path: '/:org_name/console-01'
    });
  });
});